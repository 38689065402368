<script setup lang="ts">
// imports
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

// composables
const { api } = useFeathers()
const display = useDisplay()

// stores
const appStore = useAppStore()
const authStore = useAuthStore()

// static
const logo = '/logo.png'

// refs
const {
  miniDrawer,
  navDrawer,
} = storeToRefs(appStore)

// computed
const { user } = storeToRefs(authStore)
const county = computed(() => user.value?.SelectedCustomer?.County || '')
const customer = computed(() => user.value?.SelectedCustomer?.CustName || '')
const ftp = computed(() => user.value?.SelectedCustomerParameters?.UserDefined1 ? `https://app.box.com/${user.value?.SelectedCustomerParameters?.UserDefined1}` : '')
const drawerItems = computed(() => {
  const drawerItems = [{
    id: 0,
    href: undefined,
    icon: '$mdiHome',
    rel: undefined,
    section: '',
    target: undefined,
    title: 'Dashboard',
    to: '/dashboard',
  }, ...(menus.data || []).map((item: any) => ({
    id: item.ID || 0,
    href: item.Title === 'FTP' && ftp.value
      ? ftp.value
      : !item?.Internal && item?.Link ? item.Link : undefined,
    icon: item?.Icon || '$mdiHome',
    rel: !item?.Internal ? 'noopener noreferrer' : undefined,
    section: item.Section || '',
    target: !item?.Internal ? '_blank' : undefined,
    title: item?.Title || '',
    to: item?.Internal && item?.Link ? item.Link : undefined,
  }))]
  return groupBy(sortBy(drawerItems, ['section', 'title']), 'section')
})
const showCounty = computed(() => county.value && !['CBOE', 'ICBOE'].includes(user.value?.SelectedCustomer?.CustomerTypeCode))
const userLogo = computed(() => user.value?.SelectedCustomerParameters?.Logo || '')

// service params
const menuParams = computed(() => ({
  query: {
    Active: true,
    ID: {
      $in: user.value?.UserGroup?.Menus || [],
    },
  },
}))

// services
const menus = api.service('pge/menus').useFind(menuParams, { paginateOn: 'server' })

// watchers
watch(
  () => display?.xs?.value,
  (nVal) => {
    navDrawer.value = nVal
    miniDrawer.value = !nVal
  },
)
watch(
  () => display?.mdAndDown?.value,
  (nVal) => {
    miniDrawer.value = nVal
  },
)

// lifecycle
onBeforeMount(() => {
  if (display?.xs?.value)
    navDrawer.value = false

  if (display?.mdAndDown?.value && !display?.xs?.value)
    miniDrawer.value = true
})
</script>

<template>
  <v-navigation-drawer
    v-model="navDrawer"
    color="base"
    elevation="2"
    :permanent="!display.xs.value"
    :rail="miniDrawer"
    rail-width="56"
    style="border-style: unset"
  >
    <div
      v-if="!miniDrawer"
      class="d-flex flex-column align-center text-center text-h6 py-2 bg-primary"
    >
      <div>{{ customer }}</div>
      <div
        v-if="showCounty"
        class="text-subtitle-1"
      >
        {{ county }} County
      </div>
      <v-img
        v-if="userLogo"
        :key="customer"
        contain
        max-height="200px"
        :src="userLogo"
        width="200px"
      />
    </div>

    <v-divider />

    <v-list>
      <template
        v-for="(groupItems, group) in drawerItems"
        :key="`navgroup-${group}`"
      >
        <div v-if="!miniDrawer && group">
          <span class="text-caption px-3">{{ group }}</span>
        </div>
        <v-divider
          v-if="miniDrawer"
          class="pb-1"
        />
        <v-list-item
          v-for="(link, li) in groupItems"
          :key="`navlink-${group}-${li}`"
          :disabled="!link.href && !link.to"
          :href="link.href"
          :rel="link.rel"
          :target="link.target"
          :to="link.to"
        >
          <template #prepend>
            <v-icon
              :icon="link.icon"
              :style="miniDrawer ? 'margin-inline-end: 0px' : ''"
            />

            <v-tooltip
              v-if="miniDrawer"
              activator="parent"
              location="end"
            >
              {{ link.title }}
            </v-tooltip>
          </template>

          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item>
      </template>
      <v-divider v-if="miniDrawer" />
    </v-list>

    <template
      v-if="!miniDrawer"
      #append
    >
      <v-img
        class="mx-auto"
        contain
        max-height="120px"
        :src="logo"
        width="120px"
      />
      <div class="text-caption pa-4 pt-2">
        <div>
          <v-icon icon="$mdiPhone" />
          +1(585) 232-4040
        </div>
        <div>
          <v-icon icon="$mdiPhone" />
          +1(800) 262-3202
        </div>
        <div>
          <v-icon icon="$mdiEmail" />
          elections@phoenix-graphics.com
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>
