<script setup lang="ts">
// stores
const cartStore = useCartStore()

// computed
const {
  cartDrawer,
  cartItems,
} = storeToRefs(cartStore)
const showBadge = computed(() => !!cartItems.value?.length)

// methods
function toggle() {
  cartDrawer.value = !cartDrawer.value
}
</script>

<template>
  <BaseButtonIcon
    id="account-switcher"
    tooltip-text="Cart"
    @click="toggle"
  >
    <v-badge
      bordered
      color="success"
      dot
      :model-value="showBadge"
    >
      <v-icon icon="$mdiCart" />
    </v-badge>
  </BaseButtonIcon>
</template>
