<template>
  <v-footer
    app
    class="layout-footer elevation-2"
    color="white"
  >
    <v-spacer />
    <span class="text-caption">Phoenix Graphics © 2024</span>
  </v-footer>
</template>

<style lang="sass">
  .layout-footer
    bottom: 0
    width: 100%
</style>
