<script setup lang="ts">
// composables
const { api } = useFeathers()

// stores
const authStore = useAuthStore()
const cartStore = useCartStore()
const orderStore = useOrderStore()

// refs
const {
  cartDrawer,
  cartItems,
  cartOrder,
  election,
  initialized,
  isElectrack,
} = storeToRefs(cartStore)

// computed
const { user } = storeToRefs(authStore)
const userID = computed(() => user.value?.ID || null)
const cartOrderId = computed(() => orders.data?.[0]?.ID || null)

// methods
const { initNewCart } = cartStore
const { startOrder } = orderStore

async function orderBallots() {
  cartDrawer.value = false
  startOrder(election.value)
}

// service params
const orderParams = computed(() => (userID.value
  ? {
      query: {
        Cart: true,
        CreatedBy: userID.value,
        $limit: 1,
        Election: {
          Customer: true,
          Orders: {
            $select: ['ID', 'JobNumber', 'OrderProducts', 'Status'],
            OrderProducts: true,
          },
          Parties: true,
        },
        OrderProducts: true,
      },
    }
  : null))
const orderProductParams = computed(() => (cartOrderId.value
  ? {
      query: {
        OrderID: cartOrderId.value,
        Product: {
          EstimatedCosts: true,
        },
      },
    }
  : null))

// services
const orders = api.service('pge/orders').useFind(orderParams, { paginateOn: 'server' })
const orderProducts = api.service('pge/orderProducts').useFind(orderProductParams, { paginateOn: 'server' })

// watchers
watch(computed(() => orders.data), (nVal, oVal) => {
  if (nVal?.[0] !== oVal?.[0])
    cartOrder.value = nVal?.[0] || null
})
watch(computed(() => orderProducts.data), (nVal, oVal) => {
  if (nVal !== oVal)
    cartItems.value = nVal as any
})

// lifecycle
onMounted(async () => {
  await orders.find()
  if (!cartOrderId.value)
    await initNewCart()

  initialized.value = true
})
</script>

<template>
  <v-navigation-drawer
    v-model="cartDrawer"
    location="right"
    style="border-style: unset"
    temporary
    width="360"
  >
    <div class="d-flex align-center justify-space-between px-4 py-2 bg-primary">
      <div class="text-h6 pl-2">
        Shopping Cart
      </div>
      <v-btn
        color="primary"
        icon="$close"
        size="small"
        variant="flat"
        @click="cartDrawer = false"
      />
    </div>
    <v-divider />
    <div
      v-if="cartItems?.length"
      class="d-flex flex-column align-center"
    >
      <div class="font-weight-medium text-subtitle2 text-center pt-2">
        {{ election?.Description }}
      </div>
      <v-divider />

      <ListCartItems show-actions />

      <v-btn
        :active="false"
        class="text-capitalize mt-4"
        color="success lighten-1"
        text="Checkout"
        to="/checkout"
        width="250"
        @click="cartDrawer = false"
      />

      <template v-if="!isElectrack">
        <div class="my-2">
          or
        </div>

        <v-btn
          :active="false"
          class="text-capitalize mb-4"
          color="error lighten-2"
          text="Order More"
          to="/product"
          width="250"
          @click.stop="orderBallots"
        />
      </template>
    </div>

    <div
      v-else
      class="d-flex flex-column align-center"
    >
      <v-icon
        class="mb-6 mt-8"
        icon="$mdiCart"
      />
      <div class="text-subtitle-2 mb-8">
        Your Cart Is Empty
      </div>
    </div>
  </v-navigation-drawer>
</template>
