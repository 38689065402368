function formatAddress(address: any, type = 'Ship', sep = '\r\n') {
  return [
    address[`${type}Name`],
    address[`${type}Address1`] || null,
    address[`${type}Address2`] || null,
    address[`${type}Address3`] || null,
    `${address[`${type}City`] ? `${address[`${type}City`]},` : ''} ${address[`${type}State`] || ''} ${address[`${type}Zip`] || ''}`,
  ].filter(v => !!v).join(sep) || ''
}

function formatDate(date: string | undefined) {
  if (!date)
    return ''

  const dateArr = date.split('T')[0].split('-')
  dateArr.push(dateArr.shift() || '')
  return dateArr.join('/')
}

function formatDateTime(date: string | undefined) {
  if (!date)
    return ''

  const dateArr = (date.split('T')[0] || '').split('-')
  const timeArr = (date.split('T')[1] || '').split(':')
  dateArr.push(dateArr.shift() || '')
  timeArr.pop()
  return `${dateArr.join('/')} ${timeArr.join(':')}`
}

function formatNumber(val: number, style: any = 'decimal') {
  const formatter = new Intl.NumberFormat('en-US', { style, currency: 'USD' })
  return formatter.format(val)
}

function formatOrderCount(qty: number) {
  return `${qty} Order${qty !== 1 ? 's' : ''}`
}

export function useFormatter() {
  return {
    formatAddress,
    formatDate,
    formatDateTime,
    formatNumber,
    formatOrderCount,
  }
}
