<script setup lang="ts">
// globals
defineEmits(['updated:files'])

// stores
const authStore = useAuthStore()
const fileUploaderStore = useFileUploaderStore()

// refs
const {
  acceptedFiles,
  dialog,
  electionId,
  files,
  fileType,
  headerText,
  importEsko,
  loading,
  orderId,
} = storeToRefs(fileUploaderStore)

// computed
const { user } = storeToRefs(authStore)
const acceptedMessage = computed(() => acceptedFiles.value?.length ? `- ${acceptedFiles.value.join(', ').replaceAll(/,/g, ' / ')} supported` : '')
const fileData = computed(() => ({
  ...(electionId.value) && { ElectionID: electionId.value },
  ...(fileType.value) && { FileTypes: [fileType.value] },
  ...(importEsko.value) && { ImportEsko: importEsko.value },
  ...(orderId.value) && { OrderID: orderId.value },
  CreatedBy: user.value?.ID || 0,
}))

// methods
const {
  reset,
  updatedFiles,
} = fileUploaderStore
function uploading(state: boolean) {
  loading.value = state
  // reset()
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="440px"
  >
    <BaseSheet
      color-header
      dividers
      no-padding
    >
      <template #header>
        <div>
          File Uploader
          <div class="text-subtitle-2">
            {{ headerText }}
          </div>
        </div>
      </template>

      <div class="text-caption text-center my-2">
        <strong>* If uploading more than 4 files, please upload a .zip file instead</strong><br>
        <strong>Limit:</strong> 25Mb/file {{ acceptedMessage }}
      </div>
      <v-divider />
      <BaseDropzone
        v-model="files"
        :additional-data="fileData"
        :options="{ acceptedFiles }"
        @update:model-value="updatedFiles(files.length)"
        @uploading="uploading"
      />

      <template #actions>
        <ButtonClose
          :loading="loading"
          @click="reset"
        />
      </template>
    </BaseSheet>
  </v-dialog>
</template>
