import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'

import aliases from '~/configs/aliases'
import defaults from '~/configs/defaults'
import icons from '~/configs/icons'
import locale from '~/configs/locale'
import theme from '~/configs/theme'

export default createVuetify({
  aliases,
  defaults,
  icons,
  locale,
  theme,
})
