<script setup lang="ts">
// stores
const authStore = useAuthStore()

// composables
const theme = useTheme()

// computed
const { isAuthenticated, user } = storeToRefs(authStore)
const dark = computed(() => !!user.value?.Settings?.dark)

// lifecycle
watch(dark, (nVal) => {
  theme.global.name.value = nVal ? 'dark' : 'light'
})
</script>

<template>
  <v-app>
    <LayoutNavDrawer v-if="isAuthenticated" />

    <LayoutCartDrawer v-if="isAuthenticated" />

    <LayoutAppBar v-if="isAuthenticated" />

    <LayoutContent />

    <LayoutAppFooter v-if="!isAuthenticated" />

    <AppSnackbar />

    <AppSystemState :authenticated="isAuthenticated" />

    <AppFileUploader />

    <AppPdfViewer />
  </v-app>
</template>

<style lang="sass">
html
  background: url("/src/assets/background.jpeg") no-repeat center center fixed
  -webkit-background-size: cover
  -moz-background-size: cover
  -o-background-size: cover
  background-size: cover
  background-color: rgba(100,100,225,.2)
  background-blend-mode: multiply

#app
  .v-application
    background-color: unset

.v-input__details
  margin-bottom: 0px !important

input[type=file]
  position: absolute
  left: -99999px

.fill-width
  width: 100%

$sizes: 50, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700
@each $size in $sizes
  .min-#{$size}
    min-width: #{$size}px
  .max-#{$size}
    max-width: #{$size}px

.pre
  white-space: pre
.pre-wrap
  white-space: pre-wrap
</style>
