import * as Sentry from '@sentry/vue'
import OpenReplay from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'

// tracker
const projectKey = import.meta.env.VITE_OPENREPLAY_KEY as string
const ingestPoint = import.meta.env.VITE_OPENREPLAY_HOST as string
const release = import.meta.env?.VITE_PLUGIN_SENTRY_CONFIG?.release || ''

export default function (app: any) {
  const tracker = new OpenReplay({
    projectKey,
    ingestPoint,
    __DISABLE_SECURE_MODE: import.meta.env.DEV,
    revID: release,
    onStart: ({ sessionID }: any) => {
      Sentry.setTag('openReplaySessionID', sessionID)
      tracker.setMetadata('sessionID', sessionID)
    },
    // remove text privacy for debugging
    defaultInputMode: 0,
    obscureInputEmails: false,
    obscureTextEmails: false,
    obscureTextNumbers: false,
  } as any)
  tracker.start()
  tracker.use(trackerAssist({}))

  useAppStore().tracker = tracker
  app.config.errorHandler = (err: any, _vm: any, _info: any) => {
    tracker.handleError(err)
    throw err
  }

  return { tracker }
}
