async function snackbarAction(action: any, successMsg = 'success', errorMsg = '', timeout = 2000) {
  const snackbar = {
    active: true,
    type: 'success',
    message: successMsg,
    timeout,
  }
  try {
    await action?.()
  }
  catch (e: any) {
    snackbar.type = 'error'
    snackbar.message = errorMsg || e.message
  }
  useAppStore().setSnackbar(snackbar)
}

export function useSnackbar() {
  return {
    snackbarAction,
  }
}
