export default {
  /* alias components */
  ButtonBack: {
    class: 'text-none mr-2',
    color: 'primary',
    text: 'Back',
  },
  ButtonCancel: {
    class: 'text-none',
    color: 'error',
    text: 'Cancel',
    variant: 'outlined',
  },
  ButtonClose: {
    class: 'text-none',
    color: 'error',
    text: 'Close',
    variant: 'outlined',
  },
  ButtonNext: {
    class: 'text-none',
    color: 'primary',
    text: 'Next',
  },
  ButtonOrderNow: {
    class: 'text-none',
    color: 'primary',
    size: 'small',
    text: 'Order Now',
  },
  ButtonResolveNow: {
    class: 'text-none',
    color: 'error',
    size: 'small',
    text: 'Resolve Now',
  },
  ButtonSave: {
    class: 'text-none',
    color: 'success',
    text: 'Save',
  },
  ButtonSubmit: {
    class: 'text-none',
    color: 'success',
    text: 'Submit',
  },
  ButtonUpdate: {
    class: 'text-none',
    color: 'success',
    text: 'Update',
  },
  ChipActionRequired: {
    class: 'mx-1',
    color: 'error',
    label: true,
    size: 'small',
    text: 'Action Required',
  },
  ChipOrders: {
    class: 'mr-2',
    color: 'primary',
    label: true,
    size: 'small',
  },
  ChipPending: {
    color: 'error',
    label: true,
    size: 'small',
    text: 'Pending District Data',
  },
  /* vuetify components */
  VAutocomplete: {
    autocomplete: 'offf',
    color: 'primary',
    density: 'compact',
    hideDetails: true,
    variant: 'outlined',
  },
  VBtn: {
    class: 'text-none',
  },
  VCheckbox: {
    density: 'compact',
    hideDetails: true,
  },
  VChip: {
    label: true,
    size: 'small',
  },
  VDataTable: {
    density: 'compact',
    fixedHeader: true,
    itemValue: 'ID',
    itemsPerPage: 50,
  },
  VDataTableVirtual: {
    density: 'compact',
    fixedHeader: true,
    itemValue: 'ID',
  },
  VDialog: {
    transition: 'dialog-bottom-transition',
  },
  VList: {
    color: 'primary',
    density: 'compact',
    nav: true,
  },
  VSelect: {
    autocomplete: 'offf',
    color: 'primary',
    density: 'compact',
    hideDetails: true,
    variant: 'outlined',
  },
  VSwitch: {
    color: 'primary',
    density: 'compact',
    hideDetails: true,
  },
  VTextField: {
    autocomplete: 'offf',
    color: 'primary',
    density: 'compact',
    hideDetails: true,
    variant: 'outlined',
  },
}
