<template>
  <v-main>
    <v-container
      fluid
      style="height: calc(100vh - 48px)"
    >
      <router-view v-slot="{ Component }">
        <v-fade-transition mode="out-in">
          <component :is="Component" />
        </v-fade-transition>
      </router-view>
    </v-container>
  </v-main>
</template>
