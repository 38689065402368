import {
  api,
  feathersClient,
} from '~/plugins/feathers'

export function useFeathers() {
  return {
    // temp fix until feathers-pinia can update
    api: api as any,
    feathersClient,
  }
}
