<script setup lang="ts">
// composables
const router = useRouter()

// stores
const appStore = useAppStore()
const authStore = useAuthStore()

// computed
const { user } = storeToRefs(authStore)
const btnText = computed(() => user.value?.FullName || '')
const isAuthenticated = computed(() => authStore?.isAuthenticated)

// methods
async function logout() {
  if (isAuthenticated.value) {
    await authStore.logout()
    router.push('/')
    appStore.setSnackbar({
      active: true,
      message: 'You Have Been Logged Out',
      timeout: 3000,
      type: 'info',
    })
  }
}
</script>

<template>
  <v-btn
    class="text-capitalize h-100 rounded-0 "
    data-cy="menu-account"
  >
    {{ btnText }}
    <v-icon icon="$mdiChevronDown" />

    <v-menu
      activator="parent"
      offset-y
      transition="slide-y-transition"
    >
      <v-list
        class="rounded-ts-0 pb-1"
        slim
      >
        <v-list-item
          disabled
          prepend-icon="$mdiHelpCircleOutline"
          title="Guides"
        />
        <v-list-item
          prepend-icon="$mdiCog"
          title="Settings"
          to="/settings"
        />
        <v-divider class="my-1" />
        <v-list-item
          data-cy="menu-account-logout"
          prepend-icon="$mdiLogout"
          title="Logout"
          @click="logout"
        />
      </v-list>
    </v-menu>
  </v-btn>
</template>
