const { api } = useFeathers()
const { snackbarAction } = useSnackbar()

export const useViewerStore = defineStore({
  id: 'viewer',
  state: () => ({
    clearRequests: false,
    clearOnApprove: false,
    dialog: false,
    error: '',
    id: null as null | number,
    file: null as null | string,
    loading: false,
    orderId: null as null | number,
    showApproval: false,
  }),
  getters: {},
  actions: {
    async approve() {
      const successMsg = 'Proof approved'
      const errorMsg = 'Failed to approving proof'
      await snackbarAction(async () => {
        const comment = `Approved on ${new Date().toLocaleString()}`
        await api.service('pge/files').patch(this.id, { ImportEsko: true, Approved: true, Comment: comment })
        await this.clearProofRequest()
      }, successMsg, errorMsg)
      this.$reset()
    },
    async clearProofRequest() {
      if (this.clearOnApprove && this.orderId) {
        await api.service('pge/orderProducts').patch(null, { RequestApproval: false }, { query: { OrderID: this.orderId } })
        this.$patch({ clearRequests: true })
      }
    },
    async reject() {
      const successMsg = 'Proof rejected'
      const errorMsg = 'Failed to rejecting proof'
      await snackbarAction(async () => {
        const comment = `Rejected on ${new Date().toLocaleString()}`
        await api.service('pge/files').patch(this.id, { ImportEsko: true, Rejected: true, Comment: comment })
      }, successMsg, errorMsg)
      this.$reset()
    },
    async reset() {
      this.$reset()
      // api.service('cc/fileUploads').store.clearAll()
    },
    async preview(id: number, orderId: number, showApproval: boolean, clearOnApprove: boolean) {
      this.$patch({ clearOnApprove, dialog: true, id, loading: true, orderId, showApproval })
      try {
        const data = await api.service('cc/fileUploads').get(id)
        if (data.File) {
          const file = URL.createObjectURL(new Blob([data.File as any], {
            type: 'application/pdf',
          }))
          this.$patch({ file })
        }
      }
      catch (e: any) {
        // quietly ignore loading error
        this.$patch({ error: e.message })
      }
      finally {
        this.$patch({ loading: false })
      }
    },
  },
})
