<script setup lang="ts">
// stores
const { snackbar } = useAppStore()
</script>

<template>
  <v-snackbar
    v-model="snackbar.active"
    :color="snackbar.type"
    location="bottom center"
    :timeout="snackbar.timeout"
    transition="fade-transition"
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>
