export default {
  variations: {
    colors: ['primary', 'secondary', 'error', 'success', 'warning'],
    lighten: 4,
    darken: 4,
  },
  themes: {
    light: {
      colors: {
        primary: '#673AB7',
      },
    },
  },
}
