<script setup lang="ts">
// composables
const { api } = useFeathers()
const router = useRouter()
const { snackbarAction } = useSnackbar()

// stores
const authStore = useAuthStore()
const cartStore = useCartStore()

// static
const loadingText = 'Switching Customer'

// refs
const active = ref(false)
const loading = ref(false)
const selected = ref(null) as any

// computed
const { user } = storeToRefs(authStore)
const {
  election,
  initialized,
} = storeToRefs(cartStore)
const currentAccount = computed(() => user.value?.SelectedCustomer?.Account || '')
const currentCustName = computed(() => user.value?.SelectedCustomer?.CustName || '')
const disableUpdate = computed(() => !selected?.value || selected?.value?.Account === currentAccount?.value)
const disableSelector = computed(() => !!election.value || !initialized.value)

// methods
async function update() {
  const customerAccount = selected.value.Account
  const customerName = selected.value.CustName
  const userID = user.value?.ID

  loading.value = true
  const successMsg = `Switched to customer ${customerName}`
  await snackbarAction(async () => {
    await api.service('pge/users').patch(userID, { SelectedCustomerAccount: customerAccount }, { query: {
      ChildCustomers: true,
      Customer: true,
      SelectedCustomer: true,
      SelectedCustomerParameters: true,
      UserGroup: true,
    } })
    if (router?.currentRoute?.value?.fullPath === '/product')
      router.push('/dashboard')
    reset()
  }, successMsg)
  loading.value = false
  /*
    await dispatch('app/setOverlay', { active: true, text: 'Switching Account... Please Wait' })
    // refresh app logics
    await commit('activityLogs/clearAll')
    await dispatch('activityLogs/find', {})
    await commit('contact/clearAll')
    await dispatch('contact/find', {})
    await commit('elections/clearAll')
    await dispatch('elections/find', {})
    await commit('notifications/clearAll')
    await dispatch('notifications/find', {})
    await commit('orders/clearAll')
    await dispatch('orders/find', {})

    await dispatch('app/setOverlay', { active: false, text: '' })
    */
}
function reset() {
  active.value = false
  setSelected()
}
function setSelected() {
  selected.value = currentAccount.value && currentCustName.value
    ? {
        Account: currentAccount.value,
        CustName: currentCustName.value,
      }
    : null
}

// watchers
watch(currentAccount, (nVal) => {
  if (nVal)
    setSelected()
})

// lifecycle
onMounted(async () => {
  setSelected()
})
</script>

<template>
  <BaseButtonIcon
    :disabled="disableSelector"
    icon="$mdiAccountSwitchOutline"
    tooltip-text="Switch Accounts"
  >
    <v-dialog
      v-model="active"
      activator="parent"
      persistent
      width="400px"
    >
      <BaseSheet
        color-header
        dividers
        :loading="loading"
        :loading-text="loadingText"
        no-padding
      >
        <template #header>
          <div>
            Customer Switcher
            <div class="text-subtitle-2">
              Current Customer: {{ currentCustName }}
            </div>
          </div>
        </template>

        <BaseAutocompleteAccount
          v-model="selected"
          class="ma-4"
          :disabled="disableSelector"
          label="Select a Customer"
          return-object
        />

        <template #actions>
          <ButtonClose @click="reset" />
          <v-spacer />
          <ButtonUpdate
            :disabled="disableUpdate"
            @click="update"
          />
        </template>
      </BaseSheet>
    </v-dialog>
  </BaseButtonIcon>
</template>
