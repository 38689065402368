import en from 'vuetify/lib/locale/en'

import enNY from '~/locales/en-NY'
import enPA from '~/locales/en-PA'

export default {
  locale: 'en',
  messages: {
    en,
    enNY: { ...en, ...enNY },
    enPA: { ...en, ...enPA },
  },
}
