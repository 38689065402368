import * as Sentry from '@sentry/vue'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { routes } from 'vue-router/auto-routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// check auth before routing
router.beforeEach(async (to: any, from: any, next: any) => {
  const authStore = useAuthStore()
  let path = null as any
  await authStore
    .reAuthenticate()
    .then((auth: any) => {
      // block route based on user perms
      useAppStore()?.tracker?.setUserID(auth.user?.EmailAddress)
      Sentry.setUser(auth.user)
      if (to.name === '/')
        path = { name: '/dashboard' }
    })
    .catch((e: any) => {
      if (e.message === 'jwt expired') {
        const { setSnackbar } = useAppStore()
        setSnackbar({
          active: true,
          message: 'You Have Been Logged Out',
          timeout: 3000,
          type: 'error',
        })
      }
      if (!['/'].includes(to.name))
        path = { name: '/', query: to.query }
    })
  next(path)
})

export default router
