export const useAuthStore = defineStore('auth', () => {
  const { api } = useFeathers()
  const auth = useAuth({
    api,
    servicePath: 'pge/users',
  })

  auth.reAuthenticate()
  return {
    ...auth,
    // user getters
    isAdmin: computed(() => auth?.user?.value?.UserGroup?.Options?.admin),
    isBOE: computed(() => ['CBOE', 'ICBOE', 'ADMIN'].includes(auth?.user?.value?.SelectedCustomer?.CustomerTypeCode)),
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore as any, import.meta.hot))
