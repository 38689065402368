import { VBtn } from 'vuetify/components/VBtn'
import { VChip } from 'vuetify/components/VChip'

export default {
  ButtonBack: VBtn,
  ButtonCancel: VBtn,
  ButtonClose: VBtn,
  ButtonNext: VBtn,
  ButtonOrderNow: VBtn,
  ButtonResolveNow: VBtn,
  ButtonSave: VBtn,
  ButtonSubmit: VBtn,
  ButtonUpdate: VBtn,
  ChipActionRequired: VChip,
  ChipOrders: VChip,
  ChipPending: VChip,
}
