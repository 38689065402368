<script setup lang="ts">
// stores
const viewerStore = useViewerStore()

// refs
const {
  dialog,
  file,
  loading,
  showApproval,
} = storeToRefs(viewerStore)

/// methods
const {
  approve,
  reject,
  reset,
} = viewerStore
</script>

<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <BaseSheet
      center
      dividers
      height="100vh"
      no-padding
      style="overflow: hidden"
    >
      <div v-if="loading">
        <BaseLoader text="Generating Preview" />
      </div>
      <object
        v-else-if="file"
        :data="file"
        height="100%"
        type="application/pdf"
        width="100%"
      />
      <div
        v-else
        class="text-h6 text-black text-center"
      >
        Unable to load file
      </div>

      <template
        v-if="!loading"
        #actions
      >
        <ButtonClose @click="reset" />
        <v-spacer />

        <template v-if="showApproval && file">
          <ButtonCancel
            class="mr-2"
            text="Reject"
            @click="reject"
          />
          <ButtonSave
            text="Approve"
            @click="approve"
          />
        </template>
      </template>
    </BaseSheet>
  </v-dialog>
</template>
