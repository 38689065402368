export const useFileUploaderStore = defineStore({
  id: 'fileUploader',
  state: () => ({
    acceptedFiles: ['csv', 'xlsx', 'pdf', 'doc', 'docx', 'txt', 'zip'],
    dialog: false,
    electionId: null as any,
    importEsko: false,
    files: [],
    fileType: null as null | string,
    headerText: '',
    loading: false,
    orderId: null as any,
    onUpdatedFiles: (_value?: any, _fileType?: any) => {},
  }),
  getters: {},
  actions: {
    async reset() {
      this.$reset()
    },
    async updatedFiles(value: any) {
      this.onUpdatedFiles(value, this.fileType)
    },
    async setFileUploader(options: any) {
      this.$patch({
        dialog: true,
        ...options,
      })
    },
  },
})
