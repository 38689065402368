<script setup lang="ts">
// globals
const props = defineProps({
  authenticated: {
    type: Boolean,
    default: false,
  },
})

// composables
const {
  api,
  feathersClient,
} = useFeathers()

// composables
const router = useRouter()

// stores
const appStore = useAppStore()
const authStore = useAuthStore()

// refs
const connected = ref(true)

// computed
const active = computed(() => !connected.value || (!props.authenticated && maintenance.value))
const color = computed(() => !connected.value ? '' : appSettings?.data?.PlannedMaintenance ? 'warning' : appSettings?.data?.MaintenanceMode ? 'error' : '')
const maintenance = computed(() => appSettings?.data?.MaintenanceMode || appSettings?.data?.PlannedMaintenance)

// services
const appSettings = api.service('pge/appSettings').useGet('system', { immediate: true })

// lifecycle
onBeforeMount(() => {
  feathersClient.io.on('connect_error', () => {
    connected.value = false
  })
  feathersClient.io.on('connect', () => {
    connected.value = true
  })
  feathersClient.io.on('disconnect', async () => {
    connected.value = false
    if (!feathersClient.io.active) {
      await feathersClient.io.connect()
      await authStore.logout()
      router.push('/')
      appStore.setSnackbar({
        active: true,
        message: 'Session Expired: You Have Been Logged Out',
        timeout: 5000,
        type: 'error',
      })
    }
  })
})
</script>

<template>
  <v-snackbar
    :color="color"
    location="top center"
    :model-value="active"
    :timeout="-1"
    transition="fade-transition"
  >
    <div class="d-flex flex-column align-center justify-center text-center">
      <template v-if="!connected">
        <div>
          <v-icon
            class="mr-2"
            icon="$mdiWifiStrengthAlertOutline"
          />
          Unable to connect to server
        </div>
      </template>

      <template v-else-if="appSettings?.data?.PlannedMaintenance">
        <div class="text-subtitle-1 d-flex align-center">
          <v-icon
            class="mr-3"
            icon="$mdiAlert"
          />
          <span v-html="appSettings?.data?.Message || ''" />
        </div>
      </template>

      <template v-else-if="appSettings?.data?.MaintenanceMode">
        <div class="text-subtitle-1 mb-3">
          <v-icon
            class="mr-2"
            icon="$mdiAlert"
          />
          System Maintenance in Progress
          <v-icon
            class="mr-2"
            icon="$mdiAlert"
          />
        </div>
        <span>We apologize for the inconvenience. <br> For immediate assistance contact us directly:</span>
        <div class="text-left mx-auto">
          <div class="pt-2">
            <v-icon class="mr-2 mb-1">
              $mdiEmail
            </v-icon> <a href="mailto:elections@phoenix-graphics.com">elections@phoenix-graphics.com</a>
          </div>
          <div>
            <v-icon class="mr-2 mb-1">
              $mdiPhone
            </v-icon>1(585) 232-4040
          </div>
          <div>
            <v-icon class="mr-2 mb-1">
              $mdiPhone
            </v-icon>1(800) 262-3202 (toll free)
          </div>
        </div>
      </template>
    </div>
  </v-snackbar>
</template>

<style lang="sass" scoped>
  a
    color: rgb(var(--v-theme-on-error)) !important
    text-decoration: none
</style>
