<script setup lang="ts">
// composables
const display = useDisplay()

// stores
const appStore = useAppStore()

// refs
const {
  miniDrawer,
  navDrawer,
} = storeToRefs(appStore)

// methods
function toggle() {
  if (display?.xs?.value)
    navDrawer.value = !navDrawer.value
  else
    miniDrawer.value = !miniDrawer.value
}
</script>

<template>
  <v-app-bar
    class="layout-app-bar"
    color="transparent"
    density="compact"
    elevation="0"
  >
    <v-sheet
      class="d-flex mx-4 align-center fill-height"
      elevation="4"
      rounded="b-lg"
      width="100%"
    >
      <BaseButtonIcon
        icon="$mdiDotsVertical"
        @click="toggle"
      />
      <v-divider vertical />

      <v-spacer />

      <v-divider vertical />
      <DialogAccount />

      <v-divider vertical />
      <BadgeCart />

      <v-divider vertical />
      <MenuAccount />
    </v-sheet>
  </v-app-bar>
</template>

<style lang="sass">
  .layout-app-bar
    .v-toolbar__content
      padding: 0 !important
</style>
