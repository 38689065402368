import {
  mdi,
  aliases as mdiAliases,
} from 'vuetify/lib/iconsets/mdi-svg'

import * as icons from '~/configs/mdi-icons'

export default {
  aliases: {
    ...mdiAliases,
    ...icons,
  },
  defaultSet: 'mdi',
  sets: {
    mdi,
  },
}
