export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    miniDrawer: false,
    navDrawer: true,
    orderQuery: {
      allCustomers: false,
      electrack: false,
      status: '',
    },
    snackbar: {
      type: 'success',
      message: '',
      timeout: 2000,
      active: false,
    },
    tracker: null as any,
  }),
  getters: {},
  actions: {
    async resetOrderQuery() {
      this.$patch({ orderQuery: {
        allCustomers: false,
        electrack: false,
        status: '',
      } })
    },
    async setSnackbar(data: any) {
      this.$patch({ snackbar: data })
    },
  },
})
