import { createApp } from 'vue'

import App from './App.vue'
import dropzone from './plugins/dropzone'
import openReplay from './plugins/openreplay'
import { pinia } from './plugins/pinia'
import router from './plugins/router'
import sentry from './plugins/sentry'
import vCalendar from './plugins/v-calendar'
import vuetify from './plugins/vuetify'

const app = createApp(App as any)
// init sentry
dropzone(app)
sentry(app, router)
vCalendar(app)

app
  .use(pinia as any)
  .use(router as any)
  .use(vuetify)
  .mount('#app')

// init openreplay
openReplay(app)
